@tailwind utilities;

@layer utilities {
  .ultra-shadow {
    box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
  }

  .ultra-card {
    @apply border border-stroke  md:border-2
        rounded-lg
        ultra-shadow
        p-4
        bg-white;
  }

  .credential-card--link {
    @apply block w-full max-w-[320px]
      cursor-pointer
      overflow-hidden;
  }

  .credential-card--link:hover {
    @apply border-brand;
  }

  .credential-card--link:focus-visible {
    @apply border-brand outline-none;
  }

  .ultra-link {
    @apply line-clamp-1 no-underline hover:underline hover:text-blue;
  }

  .transform-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
