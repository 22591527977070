/** customize css utilities used for ui components **/

@tailwind components;
@tailwind utilities;

@layer utilities {
  .trigger-button {
    @apply inline-flex items-center justify-center whitespace-nowrap rounded-sm font-medium text-sm;
  }
  .trigger-button:disabled {
    @apply opacity-50 pointer-events-none;
  }
}
