@tailwind utilities;
@tailwind components;

@layer components {
  .dialog-content {
    @apply fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg;
  }

  .dialog-close-btn {
    @apply absolute border-2 border-transparent right-4 top-3 rounded-sm ring-offset-white transition-opacity hover:opacity-100 hover:ring-2 hover:border-2 focus:outline-none focus:ring-2 focus:ring-brand hover:text-brand focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-neutral-100 data-[state=open]:text-neutral-500;
  }

  .dialog-description {
    @apply text-sm text-dark-2;
  }

  .dialog-footer {
    @apply flex flex-col-reverse gap-2 sm:flex-row sm:justify-end sm:space-x-2;
  }

  .dialog-header {
    @apply text-dark-2 flex flex-col space-y-1.5 text-center sm:text-left;
  }

  .dialog-overlay {
    @apply fixed inset-0 z-50 bg-dark-6/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0;
  }

  .dialog-title {
    @apply text-lg font-medium leading-none tracking-tight;
  }
}
