@tailwind utilities;
@tailwind components;

@layer components {
  .switch__wrapper {
    @apply flex items-center gap-2;
  }
  .switch {
    @apply border-2 border-brand items-center gap-2 w-10 inline-flex h-5 shrink-0 cursor-pointer rounded-full border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2  focus-visible:ring-offset-2 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-brand data-[state=unchecked]:bg-white data-[state=unchecked]:border-dark;
  }
  .switch__thumb {
    @apply border-2 pointer-events-none block h-4 w-4 rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0;
  }
  .switch__label {
    @apply text-sm whitespace-nowrap;
  }
}
