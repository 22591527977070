@tailwind utilities;
@tailwind components;

@layer components {
  .radio-group {
    @apply grid gap-2;
  }
  .radio-group__item {
    @apply flex gap-2 items-center cursor-pointer disabled:cursor-not-allowed disabled:opacity-50;
  }
  .radio-group__radio {
    @apply aspect-square h-5 w-5 rounded-full border-2 border-dark-2 disabled:opacity-50 disabled:cursor-not-allowed;
  }

  .radio-group__radio-label {
    @apply text-sm;
  }

  .radio-indicator {
    @apply flex items-center justify-center;
  }

  [aria-checked='true'].radio-group__radio {
    @apply border-brand;
  }

  [aria-checked='true'].radio-group__radio .radio-indicator {
    @apply text-brand;
  }
}
