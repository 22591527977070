@tailwind base;

html,
body,
#root {
  height: 100%;
  font-family: 'Noto Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
[role='heading'] {
  font-family: 'Fjalla One', sans-serif;
}

img {
  max-width: 100%;
}

ul,
li,
ol,
li {
  @apply list-none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-stroke
    rounded-md;
}

::-webkit-scrollbar-thumb {
  @apply bg-white
  rounded-md
  mx-1;
}
